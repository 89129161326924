<template>
  <div class="modal-body">
    <component :is="modalContentName" :modalData="modalData" :changesAccepted="changesAccepted" @allowChanges="allowChanges" ref="form"></component>
  </div>
</template>

<script>
import rulesForm from './../../rules/rulesForm/rulesForm'
import batchAddRule from './../../rules/batchAddRule/batchAddRuleModal'

export default {
  name: 'modalBody',
  components: { rulesForm, batchAddRule },
  props: ['modalContentName', 'modalData', 'changesAccepted'],
  methods: {
    allowChanges(value){
      this.$emit('allowChanges', this.$refs.form.allowChanges(value))
    }
  }
}
</script>

<style scoped></style>
