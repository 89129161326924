<template>
  <div id="promotionErrors">
    <div v-if="errors.length > 0">
      <div class="progress errors-bar mb-2 ">
        <div v-for="error in errorsList " :key="error.short_description" class="progress-bar error-color-1 "
             aria-valuemax="100 " aria-valuemin="0 " :aria-valuenow="error.percentage " role="progressbar "
             v-bind:style="{width: error.percentage + '%'} ">
          <div v-if="error.percentage > 5">{{ Math.round(error.percentage) }}%</div>
        </div>
      </div>
      <div class="error-list d-flex flex-column">
        <div v-for="error in errorsList" :key="error.short_description" class="error-item d-flex flex-row align-items-center">
          <i class="fa fa-circle mr-1" aria-hidden="true"/>
          <span v-if="(error.percentage <=5) && (error.percentage > 0)" v-text="`${Math.round(error.percentage)}%`" class="mr-1"/>
          {{ error.short_description }}

          <template v-if="error.href">
            <a :href="error.href" title="Open in new page" target="_blank" class="mx-1"><i class="fa fa-external-link"/></a>
          </template>

          <i class="fa fa-exclamation-circle ml-2" aria-hidden="true" data-toggle="tooltip" data-placement="top"
             :title="error.description" :data-original-title="error.description"/>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end" v-if="redemptionsTotal > 0">
      <div class="btn-group">
        <button type="button" class="btn btn-sm bg-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false">
          Email Report
        </button>
        <div class="dropdown-menu dropdown-menu-right">
          <a class="dropdown-item" :href="downloadReportLinkCSV" @click="downloadReport">Redemptions
            Report CSV</a>
          <a class="dropdown-item" :href="downloadReportLinkXLSX" @click="downloadReport">Redemptions
            Report XLSX</a>
          <a class="dropdown-item" :href="downloadSuccessfulReportLinkCSV" @click="downloadReport">Successful
            Redemptions Report CSV</a>
          <a class="dropdown-item" :href="downloadSuccessfulReportLinkXLSX" @click="downloadReport">Successful
            Redemptions Report XLSX</a>
          <a class="dropdown-item" :href="downloadBonusLinkCSV" @click="downloadReport">Bonus Report
            CSV</a>
          <a class="dropdown-item" :href="downloadBonusLinkXLSX" @click="downloadReport">Bonus Report
            XLSX</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import qs from "qs";

export default {
  props: ['errors', 'promotionProjectId', 'promotionSlug', 'redemptionsTotal'],
  data: function () {
    return {
      visibleIcon: false
    }
  },

  mounted: function () {
    if ($('[data-toggle="tooltip"]').length) {
      $('[data-toggle="tooltip"]').tooltip({
        delay: {
          "show": 100,
          "hide": 100
        },
        container: "body",
        title: () => ($(this).attr('title'))
      });
    }
  },

  computed: {
    headers() {
      return {
        'X-CSRF-Token': document.querySelector("meta[name='csrf-token']")?.content,
      }
    },

    otherErrorsPercentage: function () {
      let errorsPercentageSum = 0;
      this.errors.forEach(error => {
        errorsPercentageSum += (error.percentage || 0)
      });

      return 100 - errorsPercentageSum;
    },

    errorsList: function () {
      const list = [...this.errors].map((item) => {
        const {code} = item;
        let href;

        if (code) {
          const params = {
            filter: {
              promotion_slug: {
                filter: this.promotionSlug,
                filterType: "text",
                type: "contains",
              },
            },
            status_type: "unsuccessful",
            error_type: code,
          };
          if (this.promotionProjectId) {
            href = `/admin/projects/${this.promotionProjectId}/redemptions#${qs.stringify(params)}`;
          }
        }

        return {
          ...item,
          href,
        };
      });

      const other = {
        short_description: 'Other errors',
        percentage: this.otherErrorsPercentage,
      };

      return (this.otherErrorsPercentage >= 1) ? [...list, other] : list;
    },
    downloadReportLinkCSV: function () {
      return this.downloadReportLink('redemptions', 'csv');
    },
    downloadSuccessfulReportLinkCSV: function () {
      return this.downloadReportLink('successful_redemptions', 'csv');
    },
    downloadBonusLinkCSV: function () {
      return this.downloadReportLink('bonuses', 'csv');
    },
    downloadReportLinkXLSX: function () {
      return this.downloadReportLink('redemptions', 'xlsx');
    },
    downloadSuccessfulReportLinkXLSX: function () {
      return this.downloadReportLink('successful_redemptions', 'xlsx');
    },
    downloadBonusLinkXLSX: function () {
      return this.downloadReportLink('bonuses', 'xlsx');
    }
  },
  methods: {
    downloadReport: function (event) {
      event.preventDefault();

      $(this.$el).find('.dropdown-menu').toggleClass('show');


      this.$http.get(event.target.href, {headers: this.headers}).then(response => {
        const { data } = response;

        $('.notifications')
          .show()
          .text(data?.message)
          .delay(5000)
          .fadeOut()
          .toggleClass('bg-warning text-dark', data?.status == 'error')

      }, () => {
        alert('unable to download report');
      })
    },

    downloadReportLink: function (type, format) {
      return '/admin/projects/' + this.promotionProjectId + '/promotions/' + this.promotionSlug + '/report?report[type]=' + type + '&report[format]=' + format;
    }
  }
}
</script>

<style scoped></style>
