<template>
  <div class="data-table">
    <notification
      v-if="message"
      :successText="!hasError ? message : ''"
      :failureText="hasError ? message : ''"
    >
    </notification>

    <div class="row">
      <div class="col col-12 action-wrapper">
        <ul class="data-table-columns">
          <li v-for="item in columnsList" :key="item">
            <input
              :value="item"
              :id="item"
              type="checkbox"
              v-model="visibleColumns"
              :disabled="(disabledColumns.indexOf(item) !== -1) || isLoading"
              @change="applyColumnDefinition(true)"
            />
            <label :for="item">{{ columnsListNames[item] }}</label>
          </li>
        </ul>
        <div class="loader" v-if="isLoading"></div>
      </div>
    </div>

    <div class="data-table-rel">
      <ag-grid-vue
          style="width: 100%; height: 76vh"
          class="ag-theme-balham ag-theme-dynamic"
          @grid-ready="onGridReady"
          :gridOptions="gridOptions"
          :columnDefs="columnDefs"
          :animateRows="true"
          :suppressDragLeaveHidesColumns="true"
          :enableCellTextSelection="true"
          :rowClassRules="rowClassRules"
          :rowModelType="rowModelType"
          :paginationPageSize="paginationPageSize"
          :cacheOverflowSize="cacheOverflowSize"
          :maxConcurrentDatasourceRequests="maxConcurrentDatasourceRequests"
          :infiniteInitialRowCount="infiniteInitialRowCount"
          :maxBlocksInCache="maxBlocksInCache"
      />

      <div v-if="!total" class="data-table-notfound">
        <span v-if="isLoading">Loading...</span>
        <span v-if="!isLoading">No items found. Please change filter settings</span>
      </div>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import axios from "axios";
import qs from "qs";
import { isEqual, isEmpty } from 'lodash';
import notification from "../notification";
import { filterParams } from "../../utils/dataTable";

export default {
  name: "DataTableRedemptions",
  props: {
    url: { type: String, required: true },
  },
  data() {
    return {
      hasError: false,
      message: null,
      isLoading: true,
      gridOptions: {},
      rowClassRules: null,
      columnDefs: null,
      rowData: null,
      rowModelType: null,
      paginationPageSize: null,
      cacheOverflowSize: null,
      maxConcurrentDatasourceRequests: null,
      infiniteInitialRowCount: null,
      maxBlocksInCache: null,
      total: null,
      gridCurrentState: {},
      loader: `<img src="/loading2.gif" alt=""/>`,
      columnsList: [
        "user",
        "report_name",
        "status",
        "created_at"
      ],
      columnsListNames: {
        user: "User",
        report_name: "Report name",
        status: "Status",
        created_at: "Created at(UTC)"
      },
      visibleColumns: [
        "user",
        "report_name",
        "status",
        "created_at",
      ],
      disabledColumns: [

      ]
    };
  },

  components: {
    AgGridVue,
    notification
  },

  beforeMount() {
    this.gridCurrentState = {...this.gridState};

    this.gridOptions.defaultColDef = {
      resizable: true
    };

    this.rowModelType = "infinite";
    this.paginationPageSize = 100;
    this.cacheOverflowSize = 2;
    this.maxConcurrentDatasourceRequests = 4;
    this.infiniteInitialRowCount = 1;
    this.maxBlocksInCache = 20;


    this.applySavedState(['visibleColumns']);
  },

  mounted() {
    this.gridApi = this.gridOptions.api;
    this.subscribeEvents();
  },

  methods: {
    subscribeEvents() {
      this.gridOptions.onFirstDataRendered = () => {
        this.applySavedState(['filter'], true);
      };

      this.gridOptions.onCellClicked = (cell)  => {
        const $parent = cell.event.target.closest('span');
        const {colId} = cell.column;

        switch (colId.replace(/_?\d+$/,'')) {
          case "user":
          case "report_name":
          case "status":
            if ($parent && $parent.classList.contains('cell-filter-clickable')) {
              this.applyFilters(colId, $parent.textContent);
            }
            break;

          default:
            break;
        }
      };

      window.addEventListener("resize", () => {
        let w = window.innerWidth;
        setTimeout(() => {
          if (window.innerWidth === w) {
            this.sizeToFit();
          }
        }, 300);
      });
    },

    applySavedState(props, initial) {
      props.forEach(prop => {
        const values = this[initial ? 'gridState' : 'gridCurrentState'][prop];
        if (values) {
          switch (prop) {
            case 'filter':
              this.gridApi.setFilterModel(this.gridCurrentState[prop]);
              this.gridApi.onFilterChanged();
              break;

            case 'visibleColumns':
              this.visibleColumns = this.gridCurrentState[prop];
              break;
          }
        }
      });
    },

    popSavedState(state) {
      this.gridCurrentState = {
        ...this.gridCurrentState,
        ...state
      };
      location.hash = qs.stringify(this.gridCurrentState);
    },

    applyFilters(filterBy, text) {
      if (filterBy && text) {
        let filterInstance = this.gridApi.getFilterInstance(filterBy);

        filterInstance.setModel({
          type: "contains",
          filter: text
        });

        this.gridApi.onFilterChanged();
      }
    },

    applyTooltips() {
      $('[data-toggle="tooltip"]').tooltip();
    },

    applyPlaceholders() {
      $('input[ref="eFloatingFilterText"], input[ref="eInput"]').each(function() {
        $(this).attr("placeholder", "Filter...");
      });
    },

    sizeToFit() {
      this.gridApi.sizeColumnsToFit();
    },

    applyColumnDefinition() {
      this.columnDefs = this.columns;
      this.popSavedState({visibleColumns: this.visibleColumns});

      setTimeout(() => {
        this.applyTooltips();
        this.applyPlaceholders();
        this.sizeToFit();
      }, 1);
    },

    updateTimeZoneName(timeZone) {
      if (timeZone) {
        this.columnsListNames = {
          ...this.columnsListNames,
          time: `${this.columnsListNames.time} (${timeZone})`
        };
      }
    },

    normalizeRequestedParams(params) {
      let options = {};

      if (params && params.startRow) {
        options.page = params.startRow / this.paginationPageSize + 1
      }

      const filterModel = (params && params.filterModel && !isEmpty(params.filterModel)) ?  params.filterModel :  (this.gridCurrentState.filter || {});

      for (let key in filterModel) {
        switch (filterModel[key].filterType) {
          case "text":
            const filterText =
              filterModel[key].filter ||
              filterModel[key].type;
            options[key] = filterText;
            break;
          case "date":
            const dateFrom = filterModel.created_at.dateFrom;
            const dateTo = filterModel.created_at.dateTo;

            switch (filterModel.created_at.type) {
              case 'equals':
                options.created_at_from = dateFrom;
                options.created_at_to = dateFrom;
                break;
              case 'inRange':
                if (new Date(dateFrom) < new Date(dateTo)) {
                  options.created_at_from = dateFrom;
                  options.created_at_to = dateTo;
                } else {
                  options.created_at_from = dateTo;
                  options.created_at_to = dateFrom;
                }

                break;
              case 'greaterThan':
                options.created_at_from = dateFrom;
                break;
              case 'lessThan':
                options.created_at_to = dateFrom;
                break;

              default:
                break;
            }
            break;

          default:
            break;
        }
      }

      return options;
    },

    onGridReady() {
      const updateData = (data) => {
        const dataSource = {
          getRows: (params) => {
            this.isLoading = true;
            axios({
              method: "get",
              url: this.requestUrl,
              params: this.normalizeRequestedParams(params)
            })
              .then(
                response => {
                  const list = response.data.list;

                  const rowThisPage = [...list];
                  let lastRow = -1;

                  if (list.length === this.paginationPageSize) {
                     lastRow = params.endRow + this.paginationPageSize;
                     this.total = params.endRow;
                  } else if (list.length < this.paginationPageSize) {
                    lastRow = params.startRow + list.length;
                    this.total = lastRow;
                  }

                  params.successCallback(rowThisPage, lastRow);

                  setTimeout(() => {
                    this.applyTooltips();
                  }, 1);
                },
                error => {
                  this.onError(error);
                }
              )
              .finally(() => {
                this.isLoading = false;
              });
          }
        };
        this.gridApi.setDatasource(dataSource);
      };

      this.isLoading = true;
      // todo: make separate request to receive count of items and filters ONLY

      axios({
        method: "get",
        url: this.requestUrl,
        params: this.normalizeRequestedParams()
      })
        .then(
          response => {
            this.total = response.data.list.length;

            this.fh = response.data.filters;
            this.updateTimeZoneName(response.data.timeZone);
            this.applyColumnDefinition();

            updateData(response.data.list);
          },
          error => {
            this.onError(error);
          }
        )
        .finally(() => {
          this.isLoading = false;
        });
    },

    dataCellRenderer(params) {
      const id = params.data && params.data.id;

      if ((params.value !== undefined) && id) {
        return `<span class="cell-filter-clickable">${params.value}</span>`;
      } else {
        return this.loader;
      }
    },

    timeCellRenderer(params) {
      const id = params.data && params.data.id;
      if (id) {
        return this.$moment.utc(params.value).format('lll');
      } else {
        return "";
      }
    },

    branchesCellRenderer(params) {
      const id = params.data && params.data.id;
      if (id) {
        return params.value.map(item => `<span class="cell-filter-clickable">${item}</span>`).join(", ")
      } else {
        return this.loader;
      }
    },

    onError(error) {
      this.hasError = true;
      this.message =
        (error.data && error.data.message) ||
        "Something went wrong. Please try again";

      this.hideNotification();
    },

    onSuccess(message) {
      this.message = message || "Success.";
      this.hideNotification();
    },

    hideNotification(timeout) {
      setTimeout(() => {
        this.message = null;
        this.hasError = false;
      }, timeout || 5000);
    },


    getFilter(filterName, options) {
      const filter = this.fh.find(item => (item.key === filterName));

      return {
        filter: options?.filterType || "agTextColumnFilter",
        floatingFilter: true,
        filterParams: filterParams({
          type: filter.type,
          list: filter.value,
          suppressAndOrCondition: filter.unique || false,
          defaultFilterOptions: ["contains"],
        }),
      }
    }
  },

  computed: {
    requestUrl() {
      return `${window.location.origin}${this.url}`;
    },
    gridState() {
      return location.hash ? qs.parse(location.hash.substring(1)) : {}
    },
    columns() {
      let list = [];
      const optionsList = {
        user: {
          headerName: this.columnsListNames["user"],
          field: "user",
          cellRenderer: this.dataCellRenderer
        },
        status: {
          headerName: this.columnsListNames["status"],
          field: "status",
          cellRenderer: this.dataCellRenderer,
          ...this.getFilter('status')
        },
        report_name: {
          headerName: this.columnsListNames["report_name"],
          field: "report_name",
          cellRenderer: this.dataCellRenderer,
          ...this.getFilter('report_name')
        },

        created_at: {
          headerName: this.columnsListNames["created_at"],
          field: "created_at",
          cellClass: ["ag-cell-time"],
          width: 160,
          minWidth: 160,
          cellRenderer: this.timeCellRenderer
        },
      };

      this.columnsList.forEach(item => {
        if (this.visibleColumns.indexOf(item) !== -1) {
          list.push(optionsList[item] || []);
        }
      });
      return list;
    }
  }
};
</script>
