<template>
  <td>
    <div class="d-flex no-wrap full-width justify-content-between">
      <a class="fa fa-eye" :href="showActionLink"></a>
      <a class="fa fa-pencil" @click="$emit('edit')" v-if="!deletedTableActive"></a>
      <i class="fa fa-trash text-danger" @click="$emit('remove')" v-if="allowDeleteAction"></i>
    </div>
  </td>
</template>

<script>


export default {
  name: 'actionButtons',
  props: ['id', 'allowDeleteAction'],
  data: function() {
    return {}
  },
  computed: {
    showActionLink: function(){ return `${window.location.href}/${this.id}` },
    editActionLink: function(){ return `${window.location.href}/${this.id}/edit` }
  }
}
</script>

<style scoped>
  a:hover{
    text-decoration: none;
  }
</style>
